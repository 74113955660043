import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../components/SubmitButton";
import { CREATE_KEY_SMS } from "../../graphql/mutations/createKeyAndSMS";
import { UPDATE_CUSTOMER_BY_ID } from "../../graphql/mutations/updateCustomerById";
const CreateKeyAndSMS = () => {
  const [createKeyAndSms, { loading }] = useMutation(CREATE_KEY_SMS);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER_BY_ID);

  const [sms_reference_id, setReferenceID] = React.useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentColor } = useStateContext();

  const validationSchema = Yup.object({
    sms_package: Yup.number().required(),
    alert_sms: Yup.number().required(),
    sms_reference_id: Yup.string().required().min(8).max(8).trim(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      sms_package: "",
      alert_sms: "",
      sms_reference_id: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const generateRamdom = () => {
    const random = Math.floor(
      Math.random() * (10000000 - 99999999 + 1) + 99999999
    );
    setReferenceID(random);
    console.log(random);
  };

  const onSubmit = async ({ alert_sms, sms_reference_id, sms_package }) => {
    console.log({
      alert_sms,
      sms_reference_id,
      sms_package,
    });
    createKeyAndSms({
      variables: {
        alert_notification_balance: alert_sms,
        sms_reference_id,
        sms_package,
      },
      onCompleted: async ({ insert_customer_provider_one }) => {
        const fk_customer_provider_id = insert_customer_provider_one?.id;
        await updateCustomer({
          variables: {
            id,
            fk_customer_provider_id,
            status: "SUCCESS",
          },
        });
        navigate("/customers");
        toast.success("Customer Created Successfully!");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  React.useEffect(() => {
    if (sms_reference_id) {
      setValue("sms_reference_id", sms_reference_id);
    }
  }, [setValue, sms_reference_id]);
  return (
    <div className="m-2 sm:mt-0 md:mx-10 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Customers"} title={"Create Key And SMS"} />
      <div className="flex flex-col justify-center items-center">
        <div className="border md:w-4/6 lg:w-3/6 p-10 w-full rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 ">
                <div className="relative">
                  <h4 className="mb-2 dark:text-gray-400 ">SMS Reference ID</h4>
                  <TextField
                    {...register("sms_reference_id", {
                      required: "sms_reference_id is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"SMS Reference ID"}
                    error={
                      touchedFields.sms_reference_id &&
                      errors.sms_reference_id &&
                      Boolean(errors.sms_reference_id)
                    }
                    helperText={
                      touchedFields.sms_reference_id &&
                      errors.sms_reference_id &&
                      errors.sms_reference_id.message
                    }
                  />
                  <button
                    onClick={generateRamdom}
                    style={{ background: currentColor }}
                    className="absolute text-sm border text-white right-2 px-4 py-3 rounded top-[38px] "
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">SMS Package</h4>
                  <TextField
                    {...register("sms_package", {
                      required: "sms_package is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"SMS Package"}
                    error={
                      touchedFields.sms_package &&
                      errors.sms_package &&
                      Boolean(errors.sms_package)
                    }
                    helperText={
                      touchedFields.sms_package &&
                      errors.sms_package &&
                      errors.sms_package.message
                    }
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Alert Noti SMS</h4>
                  <TextField
                    {...register("alert_sms", {
                      required: "alert_sms is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"Alert Noti SMS"}
                    error={
                      touchedFields.alert_sms &&
                      errors.alert_sms &&
                      Boolean(errors.alert_sms)
                    }
                    helperText={
                      touchedFields.alert_sms &&
                      errors.alert_sms &&
                      errors.alert_sms.message
                    }
                  />
                  <span className="text-red-500"></span>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <SubmitButton
                width={"full"}
                type="submit"
                disabled={loading || !(isValid && isDirty)}
                text={loading ? "Creating..." : "Save"}
                borderRadius={8}
                bgColor={currentColor}
                color="#fff"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateKeyAndSMS;

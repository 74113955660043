import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../components/SubmitButton";
import useAuth from "../../hooks/useAuth";
import { CREATE_CONSUMERS } from "../../graphql/mutations/addConsumers";
import { UPDATE_BY_ID } from "../../graphql/mutations/updateAdminById";
import { GET_ADMIN_BY } from "../../graphql/queries/getAdminById";
import { GET_ALL_PROVIDERS } from "../../graphql/queries/getAllProviders";
const AddConsumers = () => {
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const { userId } = useAuth();
  const [createConsumer] = useMutation(CREATE_CONSUMERS);
  const [updateAdmin, { loading }] = useMutation(UPDATE_BY_ID);
  const { data } = useQuery(GET_ADMIN_BY, {
    variables: {
      id: userId,
    },
  });
  const admin = data?.admin_by_pk ? data?.admin_by_pk : null;

  const validationSchema = Yup.object({
    sms_package: Yup.number().required(),
    operator_name: Yup.string().required().max(255).trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      sms_package: "",
      operator_name: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async ({ operator_name, sms_package }) => {
    console.log({ operator_name, sms_package });
    await createConsumer({
      variables: {
        provider_name: operator_name,
        sms_package,
        fk_admin_id: userId,
      },
      onCompleted: async ({ insert_providers_one }) => {
        if (insert_providers_one) {
          await updateAdmin({
            variables: {
              id: insert_providers_one?.fk_admin_id,
              sms_package: admin?.sms_package + sms_package,
            },
            refetchQueries: () => [
              {
                query: GET_ALL_PROVIDERS,
                variables: {},
              },
            ],
          });
          navigate("/consumers");
          toast.success("Consumers Created Successfully!");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };
  return (
    <div className="m-2 sm:mt-0 md:mx-10 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Pages"} title={"Create Consumers"} />
      <div className="flex flex-col justify-center items-center">
        <div className="border sm:w-3/6 p-10  rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Operator Name</h4>
                  <TextField
                    {...register("operator_name", {
                      required: "operator_name is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"Operator Name"}
                    error={
                      touchedFields.operator_name &&
                      errors.operator_name &&
                      Boolean(errors.operator_name)
                    }
                    helperText={
                      touchedFields.operator_name &&
                      errors.operator_name &&
                      errors.operator_name.message
                    }
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">SMS Package</h4>
                  <TextField
                    {...register("sms_package", {
                      required: "sms_package is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"SMS Package"}
                    error={
                      touchedFields.sms_package &&
                      errors.sms_package &&
                      Boolean(errors.sms_package)
                    }
                    helperText={
                      touchedFields.sms_package &&
                      errors.sms_package &&
                      errors.sms_package.message
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <SubmitButton
                width={"full"}
                type="submit"
                disabled={loading || !(isValid && isDirty)}
                text={loading ? "Creating..." : "Save"}
                borderRadius={8}
                bgColor={currentColor}
                color="#fff"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddConsumers;

import React from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import Header from "../../components/Header";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PaginationButtons, SelectBox, TextField } from "../../components";
import { formatDate } from "../../data/dummy";
import { getAllCustomerTransactions } from "../../graphql/queries/getAllCustomerTransaction";
import { groupBy } from "lodash";
import ReactDatePicker from "react-datepicker";
import { GET_ALL_TRANSACTIONS_BY_ID } from "../../graphql/queries/transactionReportByID";
const CustomersDetail = () => {
  const { currentColor } = useStateContext();
  const { id } = useParams();
  const [selected, setSelected] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = React.useState(currentDate);
  const [endDate, setEndDate] = React.useState(new Date());
  const [searchPhone, setSearchPhone] = React.useState("");
  const [totalPages, setTotalPage] = React.useState(0);

  const { data } = useQuery(getAllCustomerTransactions, {
    variables: {
      id,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: transactions } = useQuery(GET_ALL_TRANSACTIONS_BY_ID, {
    variables: {
      id,
      datefilter:
        startDate && endDate
          ? { _gte: startDate.toISOString(), _lte: endDate.toISOString() }
          : { _is_null: false },
    },
  });
  const count = transactions?.transaction_reports_aggregate?.aggregate?.count;
  React.useEffect(() => {
    if (count) {
      setTotalPage(Math.round(count / 10));
    }
  }, [count]);
  const transaction_reports =
    Array.isArray(transactions?.transaction_reports) &&
    transactions?.transaction_reports.length > 0
      ? transactions?.transaction_reports
      : [];

  const getTransaction = (transactionData) => {
    return Object.entries(
      groupBy(
        transactionData
          .filter((p) => (!searchPhone ? p : p.mobile_phone === searchPhone))

          .filter((status) =>
            !selected?.username || selected?.username === "ALL"
              ? status
              : status.status === selected?.username
          )
      )
    );
  };
  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-16 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl ">
      <div className="grid grid-cols-12 gap-4 sm:gap-6 w-full">
        <div className="md:col-span-6 col-span-12 lg:col-span-4">
          <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white shadow rounded-lg">
            <h2 className="text-xl font-bold dark:text-white text-gray-600">
              SMS Package
            </h2>

            <h4
              className="mt-3 text-lg font-bold"
              style={{ color: currentColor }}
            >
              {data?.customers_by_pk?.customer_provider.sms_package}
            </h4>
          </div>
        </div>
        <div className="md:col-span-6 col-span-12 lg:col-span-4">
          <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white shadow rounded-lg">
            <h2 className="text-xl font-bold dark:text-white text-gray-600">
              Available SMS
            </h2>

            <h4
              className="mt-3 text-lg font-bold"
              style={{ color: currentColor }}
            >
              {data?.customers_by_pk?.customer_provider.sms_package -
                data?.customers_by_pk?.customer_provider.used_sms}
            </h4>
          </div>
        </div>
        <div className="md:col-span-6 col-span-12 lg:col-span-4">
          <div className="p-4 flex flex-col justify-center items-center dark:bg-secondary-dark-bg bg-white shadow rounded-lg">
            <h2 className="text-xl font-bold dark:text-white text-gray-600">
              Used SMS
            </h2>

            <h4
              className="mt-3 text-lg font-bold"
              style={{ color: currentColor }}
            >
              {data?.customers_by_pk?.customer_provider.used_sms}
            </h4>
          </div>
        </div>
      </div>

      <div className="mt-10 h-full ">
        <Header
          category={data?.customers_by_pk?.phone}
          title={data?.customers_by_pk?.username}
        />

        <div className="w-full h-full">
          <div className="mb-4 grid grid-cols-12 gap-4 ">
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 text-gray-400">
              <h4>Filter By Phone</h4>
              <TextField
                inputSize="small"
                fullWidth
                placeholder="Search By Phone"
                className="placeholder:text-sm "
                onChange={(e) => setSearchPhone((prev) => e.target.value)}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 text-gray-400">
              <h4>Filter By From Date</h4>
              <div className="border px-3 py-[9px] rounded-lg">
                <ReactDatePicker
                  className=" bg-transparent w-full outline-none border-none dark:text-white text-gray-400 cursor-pointer"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 text-gray-400">
              <h4>Filter By To Date</h4>
              <div className="border px-3 py-[9px] rounded-lg">
                <ReactDatePicker
                  className=" bg-transparent outline-none border-none dark:text-white text-gray-400 cursor-pointer"
                  placeholderText="Filter By To Date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 text-gray-400">
              <h4>Filter By Status</h4>
              <div className="w-full">
                <SelectBox
                  name="Filter By Status"
                  data={[
                    { id: 1, username: "ALL" },
                    { id: 2, username: "SUCCESS" },
                    { id: 5, username: "FAILED" },
                  ]}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </div>
          </div>
          <table className="w-full h-full">
            <thead>
              <tr>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  No.
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  TransactionID
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  Date/Time
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  MobileNo
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  Sender ID
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  Operator Name
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  MessageBody
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  Status
                </th>
                <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                  MessageLength
                </th>
              </tr>
            </thead>
            <tbody>
              {getTransaction(transaction_reports).length ? (
                getTransaction(transaction_reports).map((d, index) =>
                  d[1]
                    .slice(currentPage * 10, 10 * (currentPage + 1))
                    .map((transaction, index) => (
                      <tr key={transaction.id}>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {index + 1}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.id}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {formatDate(transaction.created_at)}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.mobile_phone}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.message_title}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.operator_name}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.message_body}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.status}
                        </td>
                        <td className="dark:text-white text-black font-normal text-sm border p-2">
                          {transaction.message_length}
                        </td>
                      </tr>
                    ))
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="py-8 text-gray-400">No data found</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
          <div>
            {transaction_reports?.length > 10 && (
              <PaginationButtons
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersDetail;

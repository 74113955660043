import React from "react";
import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
const PaginationButtons = ({ totalPages, currentPage, setCurrentPage }) => {
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const showNextButton = currentPage !== totalPages - 1;
  const showPrevButton = currentPage !== 0;
  return (
    <div>
      <ReactPaginate
        onPageChange={handlePageChange}
        breakLabel={<span className="dark:text-white text-black">...</span>}
        nextLabel={
          showNextButton ? (
            <span className="w-10 h-10 items-center flex justify-center bg-slate-100 rounded-md">
              <BsChevronRight />
            </span>
          ) : null
        }
        pageRangeDisplayed={3}
        pageCount={totalPages}
        previousLabel={
          showPrevButton ? (
            <span className="w-10 h-10 items-center flex justify-center bg-slate-100 rounded-md mr-4">
              <BsChevronLeft />
            </span>
          ) : null
        }
        containerClassName="flex items-center justify-center mt-8 mb-4"
        pageClassName="block border border-solid border-gray-400 hover:bg-[#03C9D7] flex items-center justify-center w-10 h-10 rounded-md  hover:text-white mr-4 dark:text-white text-black"
        activeClassName={`bg-[#03C9D7] text-white`}
      />
    </div>
  );
};

export default PaginationButtons;

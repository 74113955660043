import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MAX_PHONE_LENGTH, MIN_PHONE_LENGTH } from "../../config/auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../components/SubmitButton";
import { UPDATE_CUSTOMER } from "../../graphql/mutations/updateCustomer";
import { toast } from "react-toastify";
import { GET_CUSTOMER_BY_ID } from "../../graphql/queries/getCustomerByID";
import { GET_ALL_CUSTOMERS } from "../../graphql/queries/getAllCustomer";
import { UPDATE_CUSTOMER_PROVIDER_BY_ID } from "../../graphql/mutations/updateCustomerProviderById";

const UpdateCustomer = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadCustomer, getCustomer] = useLazyQuery(GET_CUSTOMER_BY_ID);
  React.useEffect(() => {
    loadCustomer({
      variables: {
        id,
      },
    });
  }, [loadCustomer, id]);
  const customer = getCustomer?.data?.customers_by_pk
    ? getCustomer.data.customers_by_pk
    : null;
  const customer_provider = customer?.customer_provider;
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [updateCustomerProvider, { loading }] = useMutation(
    UPDATE_CUSTOMER_PROVIDER_BY_ID
  );
  const validationSchema = Yup.object({
    username: Yup.string().required().max(255).trim(),
    email: Yup.string().required().max(255).trim(),
    phone: Yup.string()
      .required()
      .min(MIN_PHONE_LENGTH)
      .max(MAX_PHONE_LENGTH)
      .trim(),
    sms_reference_id: Yup.string().required().min(8).max(8),
    alert_notification_balance: Yup.number().required(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      email: "",
      phone: "",
      sms_reference_id: "",
      alert_notification_balance: "",
    },
    resolver: yupResolver(validationSchema),
  });
  React.useEffect(() => {
    if (customer) {
      setValue("username", customer?.username);
      setValue("email", customer?.email);
      setValue("phone", customer?.phone);

      setValue("sms_reference_id", customer_provider?.sms_reference_id);

      setValue(
        "alert_notification_balance",
        customer_provider?.alert_notification_balance
      );
    }
  }, [setValue, customer, customer_provider]);
  const generateRamdom = () => {
    const random = Math.floor(
      Math.random() * (10000000 - 99999999 + 1) + 99999999
    ).toString();
    setValue("sms_reference_id", random);
  };
  const onSubmit = async ({
    username,
    email,
    phone,
    alert_notification_balance,
    sms_reference_id,
  }) => {
    await updateCustomer({
      variables: {
        id,
        username,
        email,
        phone,
      },
      refetchQueries: () => [
        {
          query: GET_ALL_CUSTOMERS,
          variables: {},
        },
      ],
      fetchPolicy: "network-only",
      onCompleted: async ({ update_customers_by_pk }) => {
        const fk_customer_provider_id =
          update_customers_by_pk?.fk_customer_provider_id;
        await updateCustomerProvider({
          variables: {
            id: fk_customer_provider_id,
            alert_notification_balance,
            sms_reference_id,
            sms_package: customer_provider?.sms_package,
          },
        });
        toast.success("Customer Updated Successfully!");
        navigate("/customers");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-24 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl h-full">
      <Header category={"Customers"} title={"Update Customer"} />
      <div className="flex flex-col justify-center items-center">
        <div className="bg-white sm:w-3/6 max-w-full w-full dark:bg-secondary-dark-bg p-10 rounded-lg border">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <h4 className="mb-2 dark:text-gray-400 ">Email</h4>
              <TextField
                {...register("email", {
                  required: "email is required field",
                })}
                disabled={loading}
                inputSize={"medium"}
                fullWidth
                placeholder={"Email"}
                type="email"
                error={
                  touchedFields.email && errors.email && Boolean(errors.email)
                }
                helperText={
                  touchedFields.email && errors.email && errors.email.message
                }
              />
            </div>
            <div className="mb-4">
              <h4 className="mb-2 dark:text-gray-400 ">Phone</h4>
              <TextField
                {...register("phone", {
                  required: "phone is required field",
                })}
                inputSize={"medium"}
                fullWidth
                type={"text"}
                disabled={loading}
                placeholder={"Phone"}
                error={
                  touchedFields.phone && errors.phone && Boolean(errors.phone)
                }
                helperText={
                  touchedFields.phone && errors.phone && errors.phone.message
                }
              />
            </div>
            <div className="mb-4">
              <h4 className="mb-2 dark:text-gray-400 ">Userame</h4>
              <TextField
                {...register("username", {
                  required: "username is required field",
                })}
                disabled={loading}
                inputSize={"medium"}
                fullWidth
                placeholder={"Usename"}
                type="text"
                error={
                  touchedFields.username &&
                  errors.username &&
                  Boolean(errors.username)
                }
                helperText={
                  touchedFields.username &&
                  errors.username &&
                  errors.username.message
                }
              />
            </div>
            {customer?.status === "SUCCESS" && (
              <>
                <div className="mb-4 relative">
                  <h4 className="mb-2 dark:text-gray-400 ">SMS Sender ID</h4>
                  <TextField
                    {...register("sms_reference_id", {
                      required: "sms_reference_id is required field",
                    })}
                    disabled={loading}
                    inputSize={"medium"}
                    fullWidth
                    placeholder={"SMS Sender ID"}
                    type="text"
                    error={
                      touchedFields.sms_reference_id &&
                      errors.sms_reference_id &&
                      Boolean(errors.sms_reference_id)
                    }
                    helperText={
                      touchedFields.sms_reference_id &&
                      errors.sms_reference_id &&
                      errors.sms_reference_id.message
                    }
                  />
                  <button
                    type="button"
                    onClick={generateRamdom}
                    style={{ background: currentColor }}
                    className="absolute text-sm border text-white right-2 px-4 py-3 rounded top-[38px] "
                  >
                    Generate
                  </button>
                </div>
                <div className="mb-4">
                  <h4 className="mb-2 dark:text-gray-400 ">Alert Noti SMS</h4>
                  <TextField
                    {...register("alert_notification_balance", {
                      required: "alert_notification_balance is required field",
                    })}
                    disabled={loading}
                    inputSize={"medium"}
                    fullWidth
                    placeholder={"Alert Noti SMS"}
                    type="text"
                    error={
                      touchedFields.alert_notification_balance &&
                      errors.alert_notification_balance &&
                      Boolean(errors.alert_notification_balance)
                    }
                    helperText={
                      touchedFields.alert_notification_balance &&
                      errors.alert_notification_balance &&
                      errors.alert_notification_balance.message
                    }
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <SubmitButton
                type="submit"
                disabled={
                  loading ||
                  !(
                    (isValid && isDirty) ||
                    watch("sms_reference_id") !==
                      customer_provider?.sms_reference_id
                  )
                }
                text={loading ? "Updating..." : "Save"}
                width={"full"}
                borderRadius={8}
                bgColor={currentColor}
                color="#fff"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomer;

import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MAX_PHONE_LENGTH, MIN_PHONE_LENGTH } from "../../config/auth";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "../../graphql/mutations/createCustomer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../components/SubmitButton";
import useAuth from "../../hooks/useAuth";
const CreateCustomer = () => {
  const [createCustomer, { loading }] = useMutation(CREATE_CUSTOMER);
  const navigate = useNavigate();
  const { userId } = useAuth();
  const { currentColor } = useStateContext();

  const validationSchema = Yup.object({
    username: Yup.string().required().max(255).trim(),
    email: Yup.string().required().max(255).trim(),
    phone: Yup.string()
      .required()
      .min(MIN_PHONE_LENGTH)
      .max(MAX_PHONE_LENGTH)
      .trim(),
    password: Yup.string().required().max(255).trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: "",
      email: "",
      phone: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = ({ username, email, phone, password }) => {
    console.log({
      username,
      email,
      phone,
      password,
      fk_admin_id: userId,
    });
    createCustomer({
      variables: {
        username,
        email,
        phone,
        password,
        fk_admin_id: userId,
      },
      onCompleted: ({ customerRegister }) => {
        if (customerRegister.success) {
          navigate("/customers");
          toast.success("Customer Created Successfully!");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };
  return (
    <div className="m-2 sm:mt-0 md:mx-10 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Customers"} title={"Create Customer"} />
      <div className="flex flex-col justify-center items-center">
        <div className="border sm:w-3/6 p-10  rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Username</h4>
                  <TextField
                    {...register("username", {
                      required: "username is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"Username"}
                    error={
                      touchedFields.username &&
                      errors.username &&
                      Boolean(errors.username)
                    }
                    helperText={
                      touchedFields.username &&
                      errors.username &&
                      errors.username.message
                    }
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Email</h4>
                  <TextField
                    {...register("email", {
                      required: "email is required field",
                    })}
                    inputSize={"medium"}
                    fullWidth
                    type={"email"}
                    disabled={loading}
                    placeholder={"Email"}
                    error={
                      touchedFields.email &&
                      errors.email &&
                      Boolean(errors.email)
                    }
                    helperText={
                      touchedFields.email &&
                      errors.email &&
                      errors.email.message
                    }
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Phone</h4>
                  <TextField
                    {...register("phone", {
                      required: "phone is required field",
                      maxLength: {
                        value: 255,
                        message: "phone must be at most 255 characters",
                      },
                      pattern: {
                        value: /^[0-9@]+$/,
                        message:
                          "phone can not contain white space and special character",
                      },
                    })}
                    inputSize={"medium"}
                    fullWidth
                    disabled={loading}
                    placeholder={"Phone"}
                    error={
                      touchedFields.phone &&
                      errors.phone &&
                      Boolean(errors.phone)
                    }
                    helperText={
                      touchedFields.phone &&
                      errors.phone &&
                      errors.phone.message
                    }
                  />
                  <span className="text-red-500"></span>
                </div>
              </div>

              <div className="col-span-12">
                <div>
                  <h4 className="mb-2 dark:text-gray-400 ">Password</h4>
                  <TextField
                    {...register("password", {
                      required: "password is required field",
                    })}
                    disabled={loading}
                    inputSize={"medium"}
                    fullWidth
                    error={
                      touchedFields.password &&
                      errors.password &&
                      Boolean(errors.password)
                    }
                    helperText={
                      touchedFields.password &&
                      errors.password &&
                      errors.password.message
                    }
                    placeholder={"Password"}
                    type="password"
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <SubmitButton
                width={"full"}
                type="submit"
                disabled={loading || !(isValid && isDirty)}
                text={loading ? "Creating..." : "Save"}
                borderRadius={8}
                bgColor={currentColor}
                color="#fff"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;

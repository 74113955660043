import { gql } from "@apollo/client";

export const GET_ADMIN_BY = gql`
  query MyQuery($id: uuid!) {
    admin_by_pk(id: $id) {
      available_sms
      sms_package
      used_sms
      id
    }
  }
`;

import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation MyMutation(
    $password: String!
    $email: String!
    $phone: String!
    $username: String!
    $fk_admin_id: String!
  ) {
    customerRegister(
      password: $password
      phone: $phone
      username: $username
      email: $email
      fk_admin_id: $fk_admin_id
    ) {
      message
      statusCode
      success
      data {
        accessToken
        expiredAt
        type
      }
    }
  }
`;

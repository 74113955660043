import { gql } from "@apollo/client";

export const get_admin_purchase_history = gql`
  query MyQuery($id: uuid!) {
    admin_purchanse_sms_history(where: { fk_provider_id: { _eq: $id } }) {
      balance
      operator_name
      from_sms_package
      id
      updated_at
      provider {
        provider_name
        sms_package
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const CREATE_PURCAHSE_ADMIN_HISTORY = gql`
  mutation MyMutation(
    $balance: Int!
    $fk_provider_id: uuid!
    $from_sms_package: Int!
    $operator_name: String!
  ) {
    insert_admin_purchanse_sms_history(
      objects: {
        balance: $balance
        fk_provider_id: $fk_provider_id
        from_sms_package: $from_sms_package
        operator_name: $operator_name
      }
    ) {
      returning {
        id
      }
    }
  }
`;

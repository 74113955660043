import {
  AddConsumers,
  AdminPurchaseHistory,
  Consumers,
  CreateCustomer,
  CreateKeyAndSMS,
  CustomerPurchaseHistory,
  CustomersDetail,
  Dashboard,
  PurchaseSMSAdmin,
  PurchaseSMSCustomer,
  PurchaseSMSForCustomer,
  TransactionReports,
  UpdateCustomer,
} from "../pages";
import MainLayout from "../layout/MainLayout";
import Customers from "../pages/Customers";

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/transaction-reports",
      element: <TransactionReports />,
    },
    {
      path: "/consumers",
      element: <Consumers />,
    },
    {
      path: "/consumers/add",
      element: <AddConsumers />,
    },
    {
      path: "/consumers/purchase/:id",
      element: <PurchaseSMSAdmin />,
    },
    {
      path: "/customers",
      element: <Customers />,
    },
    {
      path: "/customers/:id",
      element: <CustomersDetail />,
    },
    {
      path: "/customers/add",
      element: <CreateCustomer />,
    },
    {
      path: "/customers/purchase",
      element: <PurchaseSMSForCustomer />,
    },
    {
      path: "/customers/purchase/:id",
      element: <PurchaseSMSCustomer />,
    },
    {
      path: "/customers/edit/:id",
      element: <UpdateCustomer />,
    },
    {
      path: "/customers/add-key-sms/:id",
      element: <CreateKeyAndSMS />,
    },
    {
      path: "/customers/history/:id",
      element: <CustomerPurchaseHistory />,
    },
    {
      path: "/consumers/history/:id",
      element: <AdminPurchaseHistory />,
    },
  ],
};

export default MainRoutes;

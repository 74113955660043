import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../components/SubmitButton";
import { GET_PROVIDER_BY_ID } from "../../graphql/queries/getProviderBy";
import { UPDATE_PROVIDER } from "../../graphql/mutations/updateProvider";
import { CREATE_PURCAHSE_ADMIN_HISTORY } from "../../graphql/mutations/createAdminPurchaseHistory";
import { GET_ALL_PROVIDERS } from "../../graphql/queries/getAllProviders";
import { UPDATE_BY_ID } from "../../graphql/mutations/updateAdminById";
import useAuth from "../../hooks/useAuth";
import { GET_ADMIN_BY } from "../../graphql/queries/getAdminById";
const PurchaseSMSAdmin = () => {
  const { id } = useParams();
  const { userId } = useAuth();
  const [updateProvider] = useMutation(UPDATE_PROVIDER);
  const { data: adminData } = useQuery(GET_ADMIN_BY, {
    variables: {
      id: userId,
    },
  });
  const [updateAdmin, { loading }] = useMutation(UPDATE_BY_ID);
  const [createAdminPurHisotry] = useMutation(CREATE_PURCAHSE_ADMIN_HISTORY);

  const { data } = useQuery(GET_PROVIDER_BY_ID, {
    variables: {
      id,
    },
  });
  const provider = data?.providers_by_pk ? data?.providers_by_pk : null;
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      balance: "",
    },
  });
  const onSubmit = async ({ balance }) => {
    try {
      await updateProvider({
        variables: {
          id,
          sms_package: provider?.sms_package + parseInt(balance),
        },
        onCompleted: async ({ update_providers_by_pk }) => {
          await createAdminPurHisotry({
            variables: {
              fk_provider_id: update_providers_by_pk?.id,
              balance,
              from_sms_package: provider?.sms_package,
              operator_name: provider?.provider_name,
            },
            refetchQueries: () => [
              {
                query: GET_ALL_PROVIDERS,
                variables: {},
              },
            ],
          });
          await updateAdmin({
            variables: {
              id: adminData?.admin_by_pk?.id,
              sms_package:
                adminData?.admin_by_pk?.sms_package + parseInt(balance),
            },
            refetchQueries: () => [
              {
                query: GET_ALL_PROVIDERS,
                variables: {},
              },
            ],
          });
          navigate("/consumers");
          toast.success("SMS Purchase Successfully!");
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="m-2 sm:mt-0 md:mx-10 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Consumers"} title={"Purchase SMS"} />
      <div className="flex flex-col justify-center items-center">
        <form
          className="border w-full sm:w-3/6 p-10 rounded"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <div>
                <h3 className="mb-8 font-bold dark:text-white text-3xl">
                  {provider?.provider_name}
                </h3>

                <div className="mb-8 ">
                  <h3
                    className="font-bold text-3xl"
                    style={{ color: currentColor }}
                  >
                    {provider?.sms_package}
                  </h3>
                  <span className="text-sm text-white bg-gray-800 rounded px-1 py-[2px]">
                    SMS PACKAGE
                  </span>
                </div>
                <h4 className="mb-2 dark:text-gray-400 ">Balance</h4>
                <TextField
                  {...register("balance", {
                    required: "balance is required field",
                  })}
                  inputSize={"medium"}
                  fullWidth
                  disabled={loading}
                  placeholder={"SMS Balance"}
                  error={
                    touchedFields.balance &&
                    errors.balance &&
                    Boolean(errors.balance)
                  }
                  helperText={
                    touchedFields.balance &&
                    errors.balance &&
                    errors.balance.message
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <SubmitButton
              type="submit"
              disabled={loading || !(isValid && isDirty)}
              text={loading ? "Creating..." : "Save"}
              borderRadius={8}
              bgColor={currentColor}
              color="#fff"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PurchaseSMSAdmin;

import React from "react";
import Header from "../components/Header";
import { useQuery } from "@apollo/client";
import { GET_ALL_CUSTOMERS } from "../graphql/queries/getAllCustomer";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useStateContext } from "../contexts/ContextProvider";
import { CiEdit } from "react-icons/ci";
import { FaEye, FaKey, FaPlus } from "react-icons/fa";
import { IconButton } from "../components";
import { formatDate } from "../data/dummy";
import { LuMessageSquarePlus } from "react-icons/lu";
import { SlNotebook } from "react-icons/sl";

const Customers = () => {
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const { data } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {},
    fetchPolicy: "network-only",
  });
  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-16 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Pages"} title={"Customers"} />
      <div className="flex flex-row space-x-4">
        <Link to={`/customers/add`} className="mb-4 inline-block">
          <Button
            text={"Register "}
            borderRadius={10}
            color={"white"}
            bgColor={currentColor}
            icon={<FaPlus size={20} style={{ marginRight: 5 }} />}
          />
        </Link>
        <Link to={`/customers/purchase`} className="mb-4 inline-block">
          <Button
            text={"Purchase SMS"}
            borderRadius={10}
            color={"white"}
            bgColor={currentColor}
            icon={<FaPlus size={20} style={{ marginRight: 5 }} />}
          />
        </Link>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="w-full">
          <thead>
            <tr>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                No
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                ReferenceID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Date/Time
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Sender ID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Email
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                MobileNo
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                SMS Package
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Available SMS
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Used SMS
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Alert Noti
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Status
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data?.customers) &&
              data?.customers.length > 0 &&
              data?.customers.map((customer, index) => {
                const customer_provider = customer.customer_provider;
                return (
                  <tr
                    // onClick={() => navigate(`/customers/${customer.id}`)}
                    key={customer.id}
                    className="hover:bg-slate-100 dark:hover:bg-main-dark-bg cursor-pointer "
                  >
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {++index}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer_provider?.sms_reference_id
                        ? customer_provider?.sms_reference_id
                        : "#"}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {formatDate(customer.created_at)}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer.username}
                    </td>
                    <td className="dark:text-white py-4 text-black font-normal text-sm border px-2">
                      {customer.email}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer.phone}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer_provider?.sms_package
                        ? customer_provider?.sms_package
                        : "#"}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer_provider
                        ? customer_provider?.sms_package -
                          customer_provider?.used_sms
                        : "#"}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer_provider?.used_sms
                        ? customer_provider?.used_sms
                        : "0"}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      {customer_provider?.alert_notification_balance
                        ? customer_provider?.alert_notification_balance
                        : "#"}
                    </td>
                    <td className="dark:text-white text-black font-normal text-sm border px-2">
                      <span
                        className={`px-3 text-white py-1 rounded ${
                          customer.status === "PENDING"
                            ? "bg-orange-400"
                            : customer.status === "SUCCESS"
                            ? "bg-green-400"
                            : customer.status === "REJECTED"
                            ? "bg-red-400"
                            : ""
                        }`}
                      >
                        {customer.status}
                      </span>
                    </td>
                    <td className="dark:text-white text-black font-normal border px-2">
                      <div className=" flex flex-row">
                        <IconButton
                          onClick={() =>
                            navigate(`/customers/edit/${customer.id}`)
                          }
                        >
                          <CiEdit size={20} />
                        </IconButton>

                        {customer.status === "PENDING" ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/customers/add-key-sms/${customer.id}`)
                            }
                          >
                            <FaKey size={20} color="grey" />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              onClick={() =>
                                navigate(`/customers/${customer.id}`)
                              }
                            >
                              <FaEye size={20} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                navigate(`/customers/purchase/${customer.id}`)
                              }
                            >
                              <LuMessageSquarePlus size={20} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                navigate(`/customers/history/${customer.id}`)
                              }
                            >
                              <SlNotebook size={20} />
                            </IconButton>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customers;

import { gql } from "@apollo/client";

export const GET_ALL_ADMIN = gql`
  query MyQuery($id: uuid!) {
    admin_by_pk(id: $id) {
      email
      id
      password
      phone
      sms_package
      used_sms
      updated_at
      username
      created_at
      available_sms
      customers {
        id
        username
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const CREATE_CONSUMERS = gql`
  mutation MyMutation(
    $provider_name: String!
    $sms_package: Int!
    $fk_admin_id: uuid!
  ) {
    insert_providers_one(
      object: {
        provider_name: $provider_name
        sms_package: $sms_package
        used_sms: 0
        fk_admin_id: $fk_admin_id
      }
    ) {
      id
      fk_admin_id
    }
  }
`;

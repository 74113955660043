import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_PROVIDER_BY_ID = gql`
  mutation MyMutation(
    $id: uuid!
    $alert_notification_balance: Int
    $sms_reference_id: String
    $sms_package: Int
  ) {
    update_customer_provider_by_pk(
      pk_columns: { id: $id }
      _set: {
        alert_notification_balance: $alert_notification_balance
        sms_reference_id: $sms_reference_id
        sms_package: $sms_package
      }
    ) {
      id
      sms_package
      customers {
        id
      }
    }
  }
`;

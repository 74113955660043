import { gql } from "@apollo/client";

export const GET_ALL_ADMIN_SUB = gql`
  subscription MySubscription($id: uuid!) {
    admin_by_pk(id: $id) {
      email
      id
      password
      phone
      sms_package
      used_sms
      updated_at
      username
      created_at
      available_sms
      customers {
        id
        username
        customer_provider {
          sms_package
          sms_reference_id
        }
      }
      providers {
        used_sms
        sms_package
        provider_name
        id
      }
    }
  }
`;

import React from "react";
import { Button, Header, IconButton } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useStateContext } from "../contexts/ContextProvider";
import { useQuery } from "@apollo/client";
import { GET_ALL_PROVIDERS } from "../graphql/queries/getAllProviders";
import { formatDate } from "../data/dummy";
import { LuMessageSquarePlus } from "react-icons/lu";
import { SlNotebook } from "react-icons/sl";

const Consumers = () => {
  const { currentColor } = useStateContext();
  const { data } = useQuery(GET_ALL_PROVIDERS);
  const navigate = useNavigate();
  const providers =
    Array.isArray(data?.providers) && data?.providers.length > 0
      ? data.providers
      : [];
  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-16 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Pages"} title={"Consumers"} />
      <div className="flex flex-row space-x-4">
        <Link to={`/consumers/add`} className="mb-4 inline-block">
          <Button
            text={"Add Operator"}
            borderRadius={10}
            color={"white"}
            bgColor={currentColor}
            icon={<FaPlus size={20} style={{ marginRight: 5 }} />}
          />
        </Link>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="w-full">
          <thead>
            <tr>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                NO
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                OPERATOR NAME
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                IMAGE
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Date/Time
              </th>

              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                SMS PACKAGE
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                AVAILABLE SMS
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                USED SMS
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {providers.map((p, index) => (
              <tr
                key={p.id}
                className="hover:bg-slate-100 dark:hover:bg-main-dark-bg cursor-pointer "
              >
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {++index}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {p.provider_name}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {p.image_url ? p.image_url : "#"}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {formatDate(p.updated_at)}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {p.sms_package}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {p.sms_package - p.used_sms}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {p.used_sms}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  <div className=" flex flex-row">
                    <IconButton
                      onClick={() => navigate(`/consumers/purchase/${p.id}`)}
                    >
                      <LuMessageSquarePlus size={20} />
                    </IconButton>
                    <IconButton
                      onClick={() => navigate(`/consumers/history/${p.id}`)}
                    >
                      <SlNotebook size={20} />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Consumers;

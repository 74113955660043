import { gql } from "@apollo/client";

export const GET_ALL_TRANSACTIONS_BY_ID = gql`
  query Query($id: uuid!, $datefilter: timestamptz_comparison_exp) {
    transaction_reports(
      order_by: { created_at: desc }
      where: { customer: { id: { _eq: $id } }, created_at: $datefilter }
    ) {
      id
      message_body
      message_length
      message_title
      mobile_phone
      operator_name
      reason
      status
      transaction_code
      updated_at
      fk_customer_id
      created_at
    }
    transaction_reports_aggregate {
      aggregate {
        count
      }
    }
  }
`;

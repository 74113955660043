import React from "react";
import { Header } from "../../components";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_CUSTOMER_PURCHASE_HISTORY_BY_CID } from "../../graphql/queries/getCustomerPurchaseHistoryByCId";
import { formatDate } from "../../data/dummy";

const CustomerPurchaseHistory = () => {
  const { id } = useParams();
  const { data } = useQuery(GET_CUSTOMER_PURCHASE_HISTORY_BY_CID, {
    variables: {
      id,
    },
  });
  const newData =
    Array.isArray(data?.customer_purchase_sms_history) &&
    data?.customer_purchase_sms_history.length > 0
      ? data?.customer_purchase_sms_history
      : [];
  console.log(newData);
  return (
    <div className="m-2 md:mx-10 sm:mt-0 mt-16 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Customer"} title={"Purchase History"} />

      <div className="overflow-x-auto w-full">
        <table className="w-full">
          <thead>
            <tr>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                NO
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                DATE
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                SENDER ID
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                ORIGINAL SMS PACKAGE
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                ADD BALANCE
              </th>
              <th className="dark:text-white bg-slate-100 dark:bg-transparent text-black font-semibold text-sm border p-3">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {newData.map((d, index) => (
              <tr
                key={d.id}
                className="hover:bg-slate-100 dark:hover:bg-main-dark-bg cursor-pointer "
              >
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {++index}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {formatDate(d.updated_at)}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {d.customer.username}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {d.from_sms_balance}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {d.balance}
                </td>
                <td className="dark:text-white text-black font-normal text-sm border px-2 py-3">
                  {d.from_sms_balance + d.balance}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerPurchaseHistory;

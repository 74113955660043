import { gql } from "@apollo/client";

export const CREATE_KEY_SMS = gql`
  mutation MyMutation(
    $sms_package: Int!
    $sms_reference_id: String!
    $alert_notification_balance: Int!
  ) {
    insert_customer_provider_one(
      object: {
        alert_notification_balance: $alert_notification_balance
        sms_package: $sms_package
        sms_reference_id: $sms_reference_id
      }
    ) {
      id
    }
  }
`;

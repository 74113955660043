import { gql } from "@apollo/client";

export const GET_ALL_PROVIDERS = gql`
  query MyQuery {
    providers {
      id
      image_url
      provider_name
      sms_package
      updated_at
      used_sms
      client_secret
      fk_admin_id
      created_at
      client_id
    }
  }
`;

import React from "react";
import { format } from "date-fns";
import { RxDashboard } from "react-icons/rx";
import { PiUsers } from "react-icons/pi";
import { GoReport } from "react-icons/go";
import { TbUsersPlus } from "react-icons/tb";

export const customer_status = [
  { id: 1, username: "PENDING" },
  { id: 2, username: "SUCCESS" },
  { id: 3, username: "REJECTED" },
];
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "MM/dd/yyyy HH:mm:ss"); // Customize the format as needed
};
export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        href: "/",
        icon: <RxDashboard />,
      },
    ],
  },
  {
    title: "Pages",
    links: [
      {
        name: "consumers",
        href: "/consumers",
        icon: <TbUsersPlus />,
      },
      {
        name: "customers",
        href: "/customers",
        icon: <PiUsers />,
      },
    ],
  },
  {
    title: "Reports",
    links: [
      {
        name: "transaction reports",
        href: "/transaction-reports",
        icon: <GoReport />,
      },
    ],
  },
];
export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];

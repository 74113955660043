import React from "react";
import Header from "../../components/Header";
import TextField from "../../components/TextField";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../../components/SubmitButton";
import { GET_ALL_CUSTOMERS } from "../../graphql/queries/getAllCustomer";
import SelectBoxUserName from "../../components/SelectUserName";
import { UPDATE_CUSTOMER_PROVIDER_BY_ID } from "../../graphql/mutations/updateCustomerProviderById";
import { UPDATE_CUSTOMER_PURCHASE_HISTORY } from "../../graphql/mutations/updateCustomerPurchaseHistory";
const PurchaseSMSForCustomer = () => {
  const [updateCustomerProvider] = useMutation(UPDATE_CUSTOMER_PROVIDER_BY_ID);
  const [updateCustomerPurHisotry, { loading }] = useMutation(
    UPDATE_CUSTOMER_PURCHASE_HISTORY
  );

  const [selectedName, setSelectName] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState({
    senderID: "",
  });
  const navigate = useNavigate();
  const { data } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {},
  });
  const customers = data?.customers ? data.customers : null;
  const { currentColor } = useStateContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      balance: "",
    },
  });
  const onSubmit = async ({ balance }) => {
    try {
      if (!selectedName?.username) {
        setErrorMessage({
          senderID: "Sender ID is required!",
        });
      }

      await updateCustomerProvider({
        variables: {
          id: selectedName?.customer_provider?.id,
          sms_package:
            selectedName?.customer_provider?.sms_package + parseInt(balance),
          sms_reference_id: selectedName?.customer_provider?.sms_reference_id,
          alert_notification_balance:
            selectedName?.customer_provider?.alert_notification_balance,
        },
        onCompleted: async ({ update_customer_provider_by_pk }) => {
          if (update_customer_provider_by_pk) {
            await updateCustomerPurHisotry({
              variables: {
                fk_customer_id: selectedName?.id,
                balance,
                from_sms_balance: selectedName?.customer_provider?.sms_package,
                status: "Success",
              },
            });
            navigate("/customers");
            toast.success("SMS Purchase Successfully!");
          }
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="m-2 sm:mt-0 md:mx-10 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category={"Customers"} title={"Purchase SMS"} />
      <div className="flex flex-col justify-center items-center">
        <form
          className="border w-full sm:w-3/6 p-10 rounded"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <div>
                <h4 className="mb-2 dark:text-gray-400 ">Username</h4>
                <SelectBoxUserName
                  name="Sender ID"
                  data={customers}
                  selected={selectedName}
                  setSelected={setSelectName}
                  errorMsg={errorMessage.senderID}
                />
              </div>
            </div>
            <div className="col-span-12">
              <div>
                <h4 className="mb-2 dark:text-gray-400 ">Balance</h4>
                <TextField
                  {...register("balance", {
                    required: "balance is required field",
                  })}
                  inputSize={"medium"}
                  fullWidth
                  disabled={loading}
                  placeholder={"SMS Balance"}
                  error={
                    touchedFields.balance &&
                    errors.balance &&
                    Boolean(errors.balance)
                  }
                  helperText={
                    touchedFields.balance &&
                    errors.balance &&
                    errors.balance.message
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <SubmitButton
              type="submit"
              disabled={loading || !(isValid && isDirty)}
              text={loading ? "Creating..." : "Save"}
              borderRadius={8}
              bgColor={currentColor}
              color="#fff"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PurchaseSMSForCustomer;

import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_PURCHASE_HISTORY = gql`
  mutation MyMutation(
    $balance: Int!
    $fk_customer_id: uuid!
    $status: String!
    $from_sms_balance: Int!
  ) {
    insert_customer_purchase_sms_history_one(
      object: {
        balance: $balance
        fk_customer_id: $fk_customer_id
        status: $status
        from_sms_balance: $from_sms_balance
      }
    ) {
      id
    }
  }
`;

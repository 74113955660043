import { gql } from "@apollo/client";

export const getAllCustomerTransactions = gql`
  query MyQuery($id: uuid!) {
    customers_by_pk(id: $id) {
      phone
      username
      email
      id
      status
      username
      customer_provider {
        sms_package
        used_sms
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_CUSTOMER_BY_ID = gql`
  query MyQuery($id: uuid!) {
    customers_by_pk(id: $id) {
      phone
      username
      email
      id
      status
      username
      fk_customer_provider_id
      customer_provider {
        id
        sms_reference_id
        sms_package
        alert_notification_balance
      }
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_PROVIDER_BY_ID = gql`
  query MyQuery($id: uuid!) {
    providers_by_pk(id: $id) {
      sms_package
      id
      provider_name
    }
  }
`;

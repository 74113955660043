import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { PiUserThin } from "react-icons/pi";
import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import useAuth from "../hooks/useAuth";

const UserProfile = ({ userData }) => {
  const { currentColor, setIsClicked, initialState } = useStateContext();
  const { logout } = useAuth();
  const handleLogout = () => {
    setIsClicked(initialState);
    logout();
  };
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          onClick={() => setIsClicked(initialState)}
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <div className="p-3 border rounded-full">
          <PiUserThin className="w-10 h-10 dark:text-white text-gray-400" />
        </div>
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {userData?.username}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">Admin</p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {userData?.email}
          </p>
        </div>
      </div>

      <div className="mt-5">
        <Button
          onClick={handleLogout}
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
        />
      </div>
    </div>
  );
};

export default UserProfile;

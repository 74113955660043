import { gql } from "@apollo/client";

export const GET_CUSTOMER_PURCHASE_HISTORY_BY_CID = gql`
  query MyQuery($id: uuid) {
    customer_purchase_sms_history(where: { fk_customer_id: { _eq: $id } }) {
      balance
      updated_at
      created_at
      from_sms_balance
      customer {
        username
        phone
      }
      id
    }
  }
`;

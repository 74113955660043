import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN,
  IS_LOGGED_IN_KEY,
  IS_LOGGED_IN_VALUE,
} from "../config/app";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logo from "../data/axpigeon.png";
import { ADMIN_LOGIN } from "../graphql/mutations/adminLogin";
import { SubmitButton, TextField } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { MAX_PHONE_LENGTH, MIN_PHONE_LENGTH } from "../config/auth";

const Login = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(ADMIN_LOGIN);

  const setAuthenticated = (accessToken) => {
    if (!accessToken) return;
    Cookies.set(IS_LOGGED_IN_KEY, IS_LOGGED_IN_VALUE);
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  };
  const validationSchema = Yup.object({
    phone: Yup.string()
      .required()
      .min(MIN_PHONE_LENGTH)
      .max(MAX_PHONE_LENGTH)
      .trim(),
    password: Yup.string().required().max(255).trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, touchedFields },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phone: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = ({ phone, password }) => {
    login({
      variables: {
        phone,
        password,
      },
      onCompleted: ({ adminLogin }) => {
        setAuthenticated(adminLogin.data.accessToken);
        navigate("/");
        toast.success("Welcome Back!");
      },
      onError: (error) => {
        console.log(error.message);
      },
    });
  };
  return (
    <div className="flex dark:bg-main-dark-bg bg-light-gray flex-col justify-center items-center h-screen px-4">
      <div className="bg-white sm:max-w-[400px] max-w-full w-full dark:bg-secondary-dark-bg p-6 rounded">
        <div className="flex  flex-row justify-center items-center">
          <Link to="/" className="dark:bg-transparent inline-block  ">
            <img src={logo} alt="logo" className="w-24 h-24 object-contain" />
          </Link>
        </div>
        <h1 className="text-2xl text-center font-bold  dark:text-white text-black/60 uppercase">
          Admin Login
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <h4 className="mb-2 dark:text-gray-400 text-white">Phone</h4>
            <TextField
              {...register("phone", {
                required: "phone is required field",
                maxLength: {
                  value: 255,
                  message: "phone must be at most 255 characters",
                },
                pattern: {
                  value: /^[0-9@]+$/,
                  message:
                    "phone can not contain white space and special character",
                },
              })}
              inputSize={"medium"}
              fullWidth
              disabled={false}
              placeholder={"Phone"}
              error={
                touchedFields.phone && errors.phone && Boolean(errors.phone)
              }
              helperText={
                touchedFields.phone && errors.phone && errors.phone.message
              }
            />
          </div>
          <div className="mb-4">
            <h4 className="mb-2 dark:text-gray-400 text-white">Password</h4>
            <TextField
              {...register("password", {
                required: "password is required field",
              })}
              disabled={loading}
              inputSize={"medium"}
              fullWidth
              error={
                touchedFields.password &&
                errors.password &&
                Boolean(errors.password)
              }
              helperText={
                touchedFields.password &&
                errors.password &&
                errors.password.message
              }
              placeholder={"Password"}
              type="password"
            />
          </div>
          <div className="mb-4">
            <SubmitButton
              type="submit"
              disabled={loading || !(isValid && isDirty)}
              text={loading ? "Please Wait..." : "Login"}
              width={"full"}
              borderRadius={8}
              bgColor={currentColor}
              color="#fff"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

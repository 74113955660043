import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMERS = gql`
  query MyQuery {
    customers(order_by: { created_at: desc }) {
      email
      created_at
      id
      email
      password
      phone
      updated_at
      username
      status
      customer_provider {
        sms_package
        sms_reference_id
        used_sms
        id
        alert_notification_balance
      }
    }
  }
`;

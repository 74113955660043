import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_BY_ID = gql`
  mutation MyMutation(
    $id: uuid!
    $fk_customer_provider_id: uuid!
    $status: String!
  ) {
    update_customers_by_pk(
      pk_columns: { id: $id }
      _set: {
        fk_customer_provider_id: $fk_customer_provider_id
        status: $status
      }
    ) {
      fk_customer_provider_id
    }
  }
`;

import { gql } from "@apollo/client";

export const UPDATE_BY_ID = gql`
  mutation MyMutation($id: uuid!, $sms_package: Int!) {
    update_admin_by_pk(
      pk_columns: { id: $id }
      _set: { sms_package: $sms_package }
    ) {
      id
    }
  }
`;

import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation MyMutation($password: String!, $phone: String!) {
    adminLogin(password: $password, phone: $phone) {
      message
      statusCode
      success
      data {
        accessToken
        expiredAt
        type
      }
    }
  }
`;

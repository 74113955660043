import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER = gql`
  mutation MyMutation(
    $id: uuid!
    $email: String!
    $phone: String!
    $username: String!
  ) {
    update_customers_by_pk(
      pk_columns: { id: $id }
      _set: { email: $email, phone: $phone, username: $username }
    ) {
      fk_customer_provider_id
      email
      phone
      username
    }
  }
`;
